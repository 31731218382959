import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import AboutNavigation from "../../components/about/aboutNavigation";
import HeroImage from "../../components/global/heroImage";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import "./faq.scss"

const AboutFaqPage = () => {
    const data = useStaticQuery(graphql`
    {
      craft {
        page: entry(section: "aboutUsFaq") {
          ... on Craft_aboutUsFaq_aboutUsFaq_Entry {
            id
            pageTitle
            uri
            section2ContentTitle
            aboutUsBodyFull
            aboutUsFaqs {
                ... on Craft_aboutUsFaqs_BlockType {
                  key: id
                  faqSection {
                    ... on Craft_faqSection_sectionTitle_BlockType {
                      sectionTitle
                    }
                    ... on Craft_faqSection_faq_BlockType {
					   key: id
                      question
                      answer
                    }
                  }
                }
            }
            aboutUsHeroImage {
              ... on Craft_aboutUsHeroImage_image_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websiteGeneral_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
          }
        }
      }
    }
  `)

    const getFaqSectionNavigation = () => {
        return data.craft.page.aboutUsFaqs.map(section => (
            <>
                <li>
                    <a href={`#${section.key}`}>{getFaqSectionTitle(section)}</a>
                    <ul>
                        {getFaqTitles(section)}
                    </ul>
                </li>
            </>
        ))
    }

    const getFaqSectionTitle = (section) => {
        let title = section.faqSection.find(elem => elem.hasOwnProperty("sectionTitle"))
        if (title) {
            return title.sectionTitle
        } else {
            return "Miscellaneous"
        }
    }

    const getFaqTitles = (section) => {
        let questions = section.faqSection.filter(elem => elem.hasOwnProperty("question"))
        if (questions) {
            return questions.map(faq => (
                <li><a href={`#${faq.key}`}>{faq.question}</a></li>
            ))
        } else {
            return <></>
        }
    }

    const getFaqSections = () => {
        return data.craft.page.aboutUsFaqs.map(section => (
            <ul className={'faq-sections'}>
                <li id={section.key} className={'section'}>
                    <span className="section-title">
                        {getFaqSectionTitle(section)}
                    </span>
                    <ul>
                        {getSectionFaqs(section)}
                    </ul>
                </li>
            </ul>
        ))
    }

    const getSectionFaqs = (section) => {
        let questions = section.faqSection.filter(elem => elem.hasOwnProperty("question"))
        if (questions) {
            return questions.map(faq => (
                <li id={faq.key} className="faq">
                    <span className="question">{faq.question}</span>
                    <p className="answer">{parse(faq.answer)}</p>
                    <div className="back-top"><a href="#top">Back to Top</a></div>
                </li>
            ))
        } else {
            return <></>
        }
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd about-faq-page">

                <PageTitle title={data.craft.page.pageTitle} />
                <HeroImage image={data.craft.page.aboutUsHeroImage}/>

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row">
                            <div className="sidebar">
                                <AboutNavigation active={data.craft.page.uri} />
                            </div>
                            <div className="content-right">
                                <h3>{data.craft.page.pageTitle}</h3>

                                {parse(data.craft.page.aboutUsBodyFull)}

                                <ul className={'faq-section-navigation'}>
                                    {getFaqSectionNavigation()}
                                </ul>

                                {getFaqSections()}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default AboutFaqPage
